import axios from "axios";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import { types } from "../../reducers/types";
import { sortBasedonSortOrder } from "./UserAction";
import { showFirstValidationErrorFromAPI } from "../../components/utility/ReUsableFunctions";

export const getCatalogueDescriptionMasterData = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "get-catalogue-information-data",
          1,
          "catalogueInformationData",
          userData?.sortFieldType?.slug || "country",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          "",
          "",
          true,
          page
        )
      );
    } else {
      axios
        .get(`${URL}/get-catalogue-information-data?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            dispatch({ type: types.IS_RELOAD, payload: false });
            dispatch({
              type: types.ADD_DATA_TO_STATE,
              payload: response.data,
              label: "catalogueDescription",
            });
          }
        })
        .catch(function (error) {
          // dispatch({ type: types.IS_LOADING, payload: false });
          console.error("..error", error);
        });
    }
  };
};

export const updateCatalogueDescription = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.IS_RELOAD, payload: false });
    let payloadData = {
      countrySlug: data?.country,
      stateSlug: data?.state,
      cohortPurposeSlug: data?.cohortPurpose,
      description: data?.catalogueDescription,
      catalogueDisplayId: data?.catalogueDisplayId,
    };
    await axios
      .put(`${URL_COHORT}/update-catalogue-information-data`, payloadData)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
        return Promise.resolve();
      })
      .catch(function (error) {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject();
      });
  };
};

export const addCatalogueDescription = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.IS_RELOAD, payload: false });
    let payloadData = {
      countrySlug: data.country,
      stateSlug: data.state,
      cohortPurposeSlug: data.cohortPurpose,
      description: data.catalogueDescription,
    };
    await axios
      .post(`${URL_COHORT}/add-catalogue-information-data`, payloadData)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
        return Promise.resolve();
      })
      .catch(function (error) {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        return Promise.reject();
      });
  };
};

// export const addNewDescription = (data) => {
//   return async (dispatch) => {
//     dispatch({ type: types.IS_RELOAD, payload: false });
//     await axios.post(`https://dev-backend.teachingknowledgeloop.com/api/v1/add-micro-catalogue-information-data`, data)
//       .then(function (response) {
//         if (response.data) {
//           dispatch({ type: types.IS_RELOAD, payload: true });
//         }
//         return Promise.resolve();
//       })
//       .catch(function (error) {
//         if (error.response?.data?.errors) {
//           let message = showFirstValidationErrorFromAPI(
//             error.response?.data?.errors
//           );
//           dispatch({
//             type: types.API_ERROR,
//             payload: message,
//           });
//         }

//         return Promise.reject();
//       });
//   };
// };

export const getCatalogueDescriptionData = (country, state, cohortPurpose) => {
  return (dispatch) => {
    axios
      .get(
        `${URL_COHORT}/get-catalogue-data/${country}/${cohortPurpose}${
          state ? "/" + state : ""
        }`
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER,
            label: "catalogueDescription",
            payload: response.data?.description || "",
          });
        }
      })
      .catch((error) => {
        console.log(".....error", error);
      });
  };
};

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addCatalogueDescription,
  updateCatalogueDescription, addNewDescription
} from "../../../actions/MasterDataActions/CatalogueDescriptionActions.js";
import {
  getCountries,
  getStates,
  getCohortPurpose,
} from "../../../actions/CohortsActions/cohortactions";
import { types } from "../../../reducers/types";
import { store } from "../../../App";
import ComponentWysiwygEditor from "../../../shared-components/wysiwyg-editor/index";
import axios from "axios";
import { URL } from "../../../api/tklEnvironments";
import { getProgramDetailsApi, getMicroDescriptioncatalogData, getDescriptioncatalogData } from "../../../actions/UserActions/ProfileActions";
function CatalogueForm(props) {
  const { updateData, isCreate, handleClose, type, selectedRadio } = props;
  const dispatch = useDispatch();
  const [acccounts, setAccounts] = useState(null);
  const [selectedAcccountSlug, setSelectedAcccountSlug] = useState("");
  const [accountError, setaccountError] = useState(false);
  const [editorDescription, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorForCreate, setDescriptionErrorForCreate] = useState(false);
  const [microProgramIdFromApi, setMicroProgramIdFromApi] = useState("");
  const [kidVisionProgramIdFromApi, setKidVisionProgramIdFromApi] = useState("");
  const programDataFromStore = useSelector((state) => state.dashboards.programData);
  const [isError, setisError] = useState(true);
  const [counter, setCounter] = useState(0);
  const catalogDescriptionData = useSelector((state) => state.dashboards.catalogDescriptionData)

  React.useEffect(() => {
    setMicroProgramIdFromApi(programDataFromStore[2]?.programId || 2)
    setKidVisionProgramIdFromApi(programDataFromStore[4]?.programId || 4)
  },[programDataFromStore])

  React.useEffect(() => {
    dispatch(getProgramDetailsApi());
  },[])
  useEffect(() => {
    // ApiCall for gpcl add catalog description on catalog description from master data fot  micro or kidvision
    if(microProgramIdFromApi || kidVisionProgramIdFromApi){
      let selected = "";
      if(selectedRadio === "Kid Vision"){
        selected = kidVisionProgramIdFromApi;
      }else{
        selected = microProgramIdFromApi;
      }
      axios.get(`${URL}/get-all-accounts/${selected}?isPaginated=0`).then((res) => { 
        let data = [];
        res?.data?.response?.map((item) =>{
          let count = 0;
          if(catalogDescriptionData?.apiData?.length === 0){
            data.push(item);
          }else{
          catalogDescriptionData?.apiData?.map((item1, index) => {
            if(item?.districtName === item1?.districtName){
              count++;
            } else if((catalogDescriptionData?.apiData?.length === (index + 1)) && (count === 0)){
              data.push(item);
            }
          })
        }
        })
        setAccounts(data)
      }).catch((err)=>{
        console.log("Api Error", err)
      })
    }
  },[microProgramIdFromApi, kidVisionProgramIdFromApi, counter])
  

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { allCountries, allStates, allCohortPurposes } = useSelector(
    (state) => state.cohorts
  );
  const catalogueRef = useRef("");
  const showtxtError = useRef(false);
  const watchCountry = watch("country");
  const count = useRef(0);
  const [editorValue, setEditorValue] = useState("");
  const [editorUpdateValue, setEditorUpdateValue] = useState(updateData?.description);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCohortPurpose());
  }, []);

  useEffect(() => {
    if (!isCreate && updateData && count.current === 0) {
      setValue("country", updateData?.country?.slug);
      setValue("state", updateData?.state?.slug);
      setValue("cohortPurpose", updateData?.cohortPurpose?.slug);
      catalogueRef.current = updateData?.catalogueDescription;
      setEditorValue(updateData?.catalogueDescription);
      dispatch(getStates(updateData?.country?.slug));
      count.current = 1;
    }
  }, [JSON.stringify(updateData)]);

  const onSubmit = (data) => {

    let apiData = {}; 
    let apiDescription = type === "accountDescriptionUpdate" ? `<p>${(editorUpdateValue.replace(/&nbsp;/g,'')).trim()}</p>` : `<p>${(editorDescription.replace(/&nbsp;/g,'')).trim()}</p>`;
    apiData["accountSlug"] = type === "accountDescriptionUpdate" ? updateData?.slug : selectedAcccountSlug;
    apiData["type"] = (selectedRadio === "Micro" || (localStorage.getItem("selectedRadioValue") === "Micro")) ? "micro" : "kidvision";  
    apiData["description"] = apiDescription;
    if(type === "accountDescription"){
    if(selectedAcccountSlug && editorDescription){
      axios.post(`${URL}/add-micro-catalogue-information-data`, apiData)
      .then(function (response) {
        if (response) {
          setCounter(1);
          dispatch(getMicroDescriptioncatalogData(1, selectedRadio));
          handleClose();
          dispatch(getDescriptioncatalogData(1, selectedRadio));
        }
      })
      .catch(function (error) {
        console.log("...error", error);
        setCounter(2);
      })
    }else{
      if(!selectedAcccountSlug && !editorDescription){
        setaccountError(true);
        setDescriptionError(true);
      }
      if(!selectedAcccountSlug){
        setaccountError(true);
      }else{
        setDescriptionError(true);
      }
    }
      
    }else if (type === "accountDescriptionUpdate"){
      // update account description
      let value = localStorage.getItem("selectedRadioValue");
       apiData["catalogueDisplayId"]= updateData?.catalogueDisplayId
        axios.put(`${URL}/update-micro-catalogue-information-data`, apiData)
        .then(function (response) {
          if (response) {
            handleClose();
            dispatch(getMicroDescriptioncatalogData(1, value));
            dispatch({type:"setApiData", apiData});
          }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }else {
      if (!editorValue && (type!= "accountDescription" && type!= "accountDescriptionUpdate")) {
        //catalogueRef.current
        showtxtError.current = true;
      } else {
        data["catalogueDescription"] = editorValue; //catalogueRef.current;
        // if (!data["state"]) data["state"] = null;
        if (isCreate) {
          dispatch(addCatalogueDescription(data)).then(() => {
            handleClose();
          });
        } else {
          data["catalogueDisplayId"] = updateData?.catalogueDisplayId;
          dispatch(updateCatalogueDescription(data)).then(() => { 
            handleClose();
          });
        }
      }
    }
  };

  useEffect(() => {
  if(selectedRadio === false){
    let stateElement = document.querySelector("#stateRef");
    watchCountry && dispatch(getStates(watchCountry));
    store.dispatch({
      type: types.USER_CERTIFICATION_DATA,
      payload: [],
      label: "allStates",
    });
    if (watchCountry === "other") stateElement.disabled = true;
    else stateElement.disabled = false;
    }
  }, [watchCountry]);

  function removeTags(str) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString(); 
    return str.replace( /(<([^>]+)>)/ig, ''); 
} 

  const courseDescriptionChangeHandlerCatalogue = (value) => {
    if(props?.type === "accountDescription") {
      let newValue = removeTags(value);
      if(newValue && ((newValue.replace(/&nbsp;/g,'')).trim()).length > 0){
      setDescription(newValue)
      setDescriptionErrorForCreate(false)
      setisError(false);
      }
      else{
        setDescription(value)
        setDescriptionErrorForCreate(true)
        setisError(true);
      }
    }else{
      let newValue = removeTags(value);
      if(newValue && ((newValue.replace(/&nbsp;/g,'')).trim()).length > 0){
          setEditorUpdateValue(newValue);
          setEditorValue(newValue);
          setDescriptionError(false)
          setisError(false);
        }else{
          setEditorUpdateValue(value);
          setEditorValue(value);
          setDescriptionError(true)
          setisError(true);
        }
    }
    // catalogueRef.current = value;
    setEditorValue(value);
    setEditorUpdateValue(value);
    setDescription(value);
    if (value && showtxtError.current) showtxtError.current = false;
  };
const accountSelected = (event) => {
    let text = event?.target?.value;
    setSelectedAcccountSlug(text);
    setaccountError(false);
  }

  return (
    <div>
      <Container>
      {(type === "accountDescription" || type === "accountDescriptionUpdate") ?
          <form 
          onSubmit= {handleSubmit(onSubmit)}
          >
            {props?.type === "accountDescription" ?
              <>
                <Form.Group as={Row}>
                  <Form.Label column sm="3" className="font-semi-bold-TKL">
                    Account Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" className="select-container">
                    <span className="select-arrow-icon material-icons">
                      expand_more
                    </span>

                    <Form.Control
                      // {...register("country", { required: true })}
                      as="select"
                      size="sm"
                      className="rounded-pill select-styles"
                      defaultValue={
                        !isCreate && updateData?.country?.slug
                          ? updateData?.country?.slug
                          : ""
                      }
                      onChange={(e) => { accountSelected(e) }}
                    >
                      <option name="" value="" selected>
                        Select Account
                      </option>
                      {acccounts?.map((account) => {
                        return (
                          <option
                            name={account?.slug}
                            value={account?.slug}
                            selected={
                              !isCreate && account.slug === updateData?.account?.slug
                            }
                          >
                            {account.districtName}
                          </option>
                        );
                      })}
                    </Form.Control>
                    { accountError && (
                        <p className="show-txt-error p-1 m-2">Account Name is required</p>
                      )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="marketLocation_UserData">
                  <Form.Label column sm="3" className="font-semi-bold-TKL">
                    Catalog Description
                  </Form.Label>
                  <Col md="3"></Col>
                </Form.Group>
              </>
              :
              <>
                <Form.Group as={Row} >
                  <Form.Label column sm="3" className="font-semi-bold-TKL">
                    Account<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" className="select-container">
                    {/* <span className="select-arrow-icon material-icons">
              expand_more
            </span> */}
                    <Form.Control
                      // {...register("country", { required: true })}
                      // as="select"
                      size="sm"
                      disabled
                      className="rounded-pill select-styles"
                      value={updateData?.districtName}
                    >

                    </Form.Control>
                    {/* {errors["country"] && (
              <p className="show-txt-error p-1 m-2">Country is required</p>
            )} */}
                  </Col>

                </Form.Group>
                <Form.Group as={Row} controlId="marketLocation_UserData">
                  <Form.Label column sm="3" className="font-semi-bold-TKL">
                    Catalog Description
                  </Form.Label>
                </Form.Group>
              </>}

            <Col xs="12" className="editor">
              <ComponentWysiwygEditor
                type="full"
                id={`catalogueDescription_description`}
                editorValue={props?.type === "accountDescription" ? editorDescription : editorUpdateValue}
                courseDescriptionChangeHandler={(value) =>
                  courseDescriptionChangeHandlerCatalogue(value)
                }
                contentHeight="100"
              />
            {/* <textarea id={`catalogueDescription_description`} onChange = {(value) => courseDescriptionChangeHandlerCatalogue(value)} >test area</textarea> */}
            </Col>
            {descriptionError && (
              <p className="show-txt-error p-1 m-2">Description is required</p>
            )}
            {
              descriptionErrorForCreate && (
                <p className="show-txt-error p-1 m-2">Description is required</p>
              )
            }
            <Form.Group as={Row} controlId="button">
              <Row className="mt-3 mr-2 ml-auto mr-3s">
                <Button
                className="btn-outline btn-danger  m-2  pl-4 pr-4 rounded-pill"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  disabled={isError}
                  type="submit"
                  className="m-2 btn btn-primary rounded-pill"
                >
                  {isCreate ? "Create" : "Update"}
                </Button>
              </Row>
            </Form.Group>
          </form>
        :
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="country">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Country<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                {...register("country", { required: true })}
                as="select"
                size="sm"
                className="rounded-pill select-styles"
                defaultValue={
                  !isCreate && updateData?.country?.slug
                    ? updateData?.country?.slug
                    : ""
                }
              >
                <option name="" value="" selected>
                  Select Country
                </option>
                {allCountries.map((country) => {
                  return (
                    <option
                      name={country.slug}
                      value={country.slug}
                      selected={
                        !isCreate && country.slug === updateData?.country?.slug
                      }
                    >
                      {country.name}
                    </option>
                  );
                })}
              </Form.Control>
              {errors["country"] && (
                <p className="show-txt-error p-1 m-2">Country is required</p>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              State{" "}
              {watch("country") !== "other" && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                {...register("state", {
                  required: watch("country") !== "other" && true,
                })}
                as="select"
                size="sm"
                id="stateRef"
                className="rounded-pill select-styles"
                defaultValue={
                  !isCreate && updateData?.state?.slug
                    ? updateData?.state?.slug
                    : ""
                }
              >
                <option name="" value="" selected>
                  Select State
                </option>
                {allStates.map((state) => {
                  return (
                    <option
                      name={state.name}
                      value={state.slug}
                      selected={
                        !isCreate && state.slug === updateData?.state?.slug
                      }
                    >
                      {state.name}
                    </option>
                  );
                })}
              </Form.Control>
              {watch("country") !== "other" && errors["state"] && (
                <p className="show-txt-error p-1 m-2">State is required</p>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="purpose">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Cohort Purpose<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>
              <Form.Control
                {...register("cohortPurpose", { required: true })}
                as="select"
                size="sm"
                className="rounded-pill select-styles"
                defaultValue={
                  !isCreate && updateData?.cohortPurpose?.slug
                    ? updateData?.cohortPurpose?.slug
                    : ""
                }
              >
                <option name="" value="" selected>
                  Select Cohort Purpose
                </option>
                {allCohortPurposes.map((purpose) => {
                  return (
                    <option
                      name={purpose.name}
                      value={purpose.slug}
                      selected={
                        updateData?.cohortPurpose?.slug === purpose.slug
                      }
                    >
                      {purpose.name}
                    </option>
                  );
                })}
              </Form.Control>
              {errors["cohortPurpose"] && (
                <p className="show-txt-error p-1 m-2">
                  Cohort Purpose is required
                </p>
              )}
            </Col>
          </Form.Group>

          <Col xs="12" className="editor">
            <ComponentWysiwygEditor
              type="full"
              id={`catalogueDescription_description`}
              editorValue={editorValue || ""}
              courseDescriptionChangeHandler={(value) =>
                courseDescriptionChangeHandlerCatalogue(value)
              }
              contentHeight="100"
            />
          </Col>
          {showtxtError.current && (
            <p className="show-txt-error p-1 m-2">Description is required</p>
          )}
          <Form.Group as={Row} controlId="button">
            <Row className="mt-3 mr-2 ml-auto mr-3s">
              <Button
                className="m-2 btn btn-secondary rounded-pill"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="m-2 btn btn-primary rounded-pill"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
            </Row>
          </Form.Group>
        </form>
}
      </Container>
    </div>
  );
}

export default CatalogueForm;

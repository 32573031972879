import React, { useContext } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import _ from "lodash";

import { store } from "../../App";
import { types } from "../../reducers/types";
import ContextUser from "../../contexts/user-context";

import { URL } from "../../api/tklEnvironments";
var moment = require("moment-timezone");
export const RestrictCopyPasteHandler = (e) => {
  e.preventDefault();
};
//hook for boolean stae updating
export const UseToggle = (initialState) => {
  const [isToggled, setIsToggled] = React.useState(initialState);
  const toggle = React.useCallback(
    () => setIsToggled((state) => !state),
    [setIsToggled]
  );
  return [isToggled, toggle];
};

export const hideLoader = async (initialTime) => {
  let finalTime = performance.now();
  if (finalTime - initialTime >= 600) {
    store.dispatch({ type: types.HIDE_LOADER });
  } else {
    setTimeout(() => {
      store.dispatch({ type: types.HIDE_LOADER });
    }, 600 - (finalTime - initialTime));
  }
  return await Promise.resolve(true);
};

export const convertToCamelcase = (str) => {
  let res = "";
  let convertedString = str?.toLowerCase();
  for (let i = 0; i < str?.length; ) {
    if (convertedString[i] !== " ") {
      res = res + convertedString[i];
      i++;
    } else {
      res = res + convertedString[++i].toUpperCase();
      i = i + 1;
    }
  }
  if(res === "e-mail"){
    return "email";
  }else{
    return res; 
  }
};
export const ASCandDSCconverstion = (str) => {
  if (str === "Descending") return "DESC";
  else return "ASC";
};

export const IsFirstRender = () => {
  const isMountRef = React.useRef(true);
  React.useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const getImg = (imgType) => {
  switch (imgType) {
    case "user":
      return "../../images.png";
    case "course":
      return "../../Banner_defaut_image.png";
    case "banner":
      return "../../images.png"; //`${process.env.PUBLIC_URL + '/Banner_defaut_image.png'}`
    default:
      return "../../images.png";
  }
};
export const BannerImageHandlingForAllDevices = (
  imgFullUrl,
  imgType,
  imgSlug
) => {
  let imgUrl = imgSlug ? imgFullUrl : getImg(imgType);
  let imgData = {
    xs: !imgUrl ? imgUrl : imgUrl,
    xs2: !imgUrl ? imgUrl : imgUrl,
    sm: !imgUrl ? imgUrl : imgUrl,
    sm2: !imgUrl ? imgUrl : imgUrl,
    md: !imgUrl ? imgUrl : imgUrl,
    md2: !imgUrl ? imgUrl : imgUrl,
    lg: !imgUrl ? imgUrl : imgUrl,
    lg2: !imgUrl ? imgUrl : imgUrl,
    xl: !imgUrl ? imgUrl : imgUrl,
    xl2: !imgUrl ? imgUrl : imgUrl,
    xxl: !imgUrl ? imgUrl : imgUrl,
    xxl2: !imgUrl ? imgUrl : imgUrl,
  };
  return imgData;
};

export const avtrBannerImagesDataPrepare = (
  fieldData,
  imageData,
  media_URL
) => {
  fieldData["name"] = imageData?.name || "";
  fieldData["slug"] = imageData?.slug || "";
  fieldData["url"] = media_URL || "";
  return fieldData;
};

export const getFullName = (firstName, MiddleName, lastName) => {
  return firstName + " " + MiddleName + " " + lastName;
};

export const errorMsgForCSVUpload = (response) => {
  let message = response.message;
  let error = response.error;
  return (
    <Container className="p-2">
      <Row className="p-2">{message}</Row>
      {error.map((msg) => (
        <Row className="p-2">
          <Col>{msg?.errors[0]}</Col>
          <Col>{msg?.values[msg?.attribute]}</Col>
        </Row>
      ))}
    </Container>
  );
};

export const prepareErrorMsg = (error) => {
  let message = error?.data.message;
  let errorMsg = error?.data?.errors;

  //"errorMsg", errorMsg)
  return (
    <Container className="card-box">
      {/* <Row className="border  p-2">
            <Col xs="4" lg="4" md="4" >
                Status Code
            </Col>
            <Col>
                {error?.status}
            </Col>

        </Row> */}

      <Row className="border  p-2">
        <Col xs="4" lg="4" md="4">
          Message
        </Col>
        <Col>{message}</Col>
      </Row>
      <Row className="border bg-light p-2 mt-2 ">
        <Col xs="4" lg="4" md="4">
          Error Field
        </Col>
        <Col>Error Message</Col>
      </Row>

      {errorMsg && (
        <Row className="border  p-2">
          {Object.keys(errorMsg)?.map(function (keyName, keyIndex) {
            return (
              <>
                <Col xs="4" lg="4" md="4" key={keyName}>
                  {keyName}
                </Col>
                <Col>{errorMsg[keyName]}</Col>
              </>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export const showFirstValidationErrorFromAPI = (errorsArray) => {
  return errorsArray[Object.keys(errorsArray)[0]][0];
};
export const lastIndexOfKey = (array, key, value) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i][`${key}`] === value) return i;
  }
  return -1;
};
export const firstIndexOfKey = (array, key, value) => {
  for (let i = 0; i <= array.length - 1; i++) {
    if (array[i][`${key}`] === value) return i;
  }
  return -1;
};

export const getArrayFieldsMatchingIndex = (arr1, arr2, arr2Index, field) => {
  let cartDataDisplayIdIndex;
  arr1 &&
    arr2 &&
    arr1.some((cartDataItem, index) => {
      if (arr2[arr2Index]?.[field] === cartDataItem?.[field]) {
        cartDataDisplayIdIndex = index;
        return index;
      }
    });
  return cartDataDisplayIdIndex;
};

export const userRoles = {
  CONTENT_ADMIN: "CONTENT ADMIN",
  CONTENT_AUTHOR: "CONTENT AUTHOR",

  DISTRICT_ADMIN: "DISTRICT ADMIN",
  SYSTEM_ADMIN: "SYSTEM ADMIN",

  TKL_MEMBER_FREE_USER: "TKL MEMBER (FREE USER)",
  COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER: "COMPREHENSIVE PROGRAM MEMBER (PAID USER)",
  GPLC_PROGRAM_MEMBER_PAID_USER: "GPLC PROGRAM MEMBER (PAID USER)",
  MICRO_COURSE_PROGRAM_MEMBER_PAID_USER: "MICRO COURSE PROGRAM MEMBER (PAID USER)",
  KIDVISION_PROGRAM_MEMBER: "KIDVISION PROGRAM MEMBER (PAID USER)",
  TKL_ACCOUNT_MANAGER: "TKL ACCOUNT MANAGER",
  TKL_FACILITATOR: "TKL FACILITATOR",
  DISTRICT_FACILITATOR: "DISTRICT FACILITATOR",
  SCHOOL_ADMIN: "SCHOOL ADMIN",
  SCHOOL_FACILITATOR: "SCHOOL FACILITATOR",
};

export const isAuthorForCourse = (
  authors,
  createdBy,
  userData,
  isCohort,
  isIntroduction
) => {
  // let userSlug2 = 'abcd'
  // console.log('......authors', authors, createdBy, userSlug)
  let userSlug = userData?.userSlug;
  if (sessionStorage.getItem("isReadOnly")) {
    return false;
  } else if (createdBy && createdBy.slug === userSlug) return true;
  else if (
    authors &&
    authors.find((authorData) => authorData?.slug === userSlug)
  ) {
    return true;
  } else if (userData?.roles.includes(userRoles?.SYSTEM_ADMIN)) {
    return true;
  } else if (userData?.roles.includes(userRoles?.CONTENT_ADMIN)) {
    if (isCohort) return true;
    return true;
  }
  return false;
};
export const isLearner = (roles) => {
  if (
    roles.find(
      (role) =>
        role === userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER ||
        role === userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER ||
        role === userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER ||
        role === userRoles?.TKL_MEMBER_FREE_USER
    )
  )
    return true;
  else return false;
};

export const isReviewer = (roles) => {
  if (
    roles.find(
      (role) =>
        role === userRoles?.TKL_FACILITATOR ||
        role === userRoles?.DISTRICT_FACILITATOR ||
        role === userRoles?.SCHOOL_FACILITATOR ||
        role === userRoles?.TKL_ACCOUNT_MANAGER ||
        role === userRoles?.SYSTEM_ADMIN
    )
  )
    return true;
  else if (roles.includes(userRoles?.SYSTEM_ADMIN)) {
    return true;
  } else return false;
};

export const isOnlyContentAdmin = (roles) => {
  if (roles.find((role) => role === userRoles?.CONTENT_ADMIN)) return true;
  else if (roles.includes(userRoles?.SYSTEM_ADMIN)) {
    return true;
  } else return false;
};

export function convertUTCDateToLocalDateUC(date) {
  if (date) {
    // var offset = moment().utcOffset();
    // var localText = moment.utc(date).utcOffset(offset).format("L LT");

    date = new Date(date);
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );

    return newDate;
  } else {
    return null;
  }
}

export function convertLocalDateToUTCDateUC(
  date,
  format = "YYYY-MM-DD HH:mm:ss Z"
) {
  if (date) {
    //@ts-ignore
    let utcDate = new moment(date).utc();
    const s = moment.utc(date).format(format);
    return s;
    // return utcDate;
  }
}

//before
export function convertUTCDateToLocalDate(date) {
  if (date) {
    var offset = moment().utcOffset();
    var localText = moment.utc(date).utcOffset(offset).format("L LT");
    return new Date(localText);
  } else {
    return null;
  }
}

export function convertLocalDateToUTCDate(date) {
  if (date) {
    //@ts-ignore
    var utcDate = new moment(date).utc();
    return utcDate;
  }
}

export function ConfirmationPopup({
  title,
  body,
  primaryBtn,
  secondaryBtn,
  handleClose,
  handleSubmit,
  show,
}) {
  return (
    <>
      <Modal className="modal-styles" show={show} onHide={handleClose}>
        <Modal.Header
        closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-outline btn-secondary rounded-pill "
            title={secondaryBtn}
            onClick={handleClose}
          >
            {secondaryBtn}
          </Button>

          <Button
            className=" btn-outline rounded-pill btn"
            title={primaryBtn}
            onClick={handleSubmit}
          >
            {primaryBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function InfoPopup({
  title,
  body,
  primaryBtn,
  secondaryBtn,
  handleSubmit,
  show,
  size,
  className = "modal-styles",
}) {
  return (
    <>
      <Modal
        size={size}
        className={className}
        show={show}
        onHide={handleSubmit}
      >
        <Modal.Header
        closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button
            className=" btn-outline rounded-pill btn"
            title={primaryBtn}
            onClick={handleSubmit}
          >
            {primaryBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function convertToISODate(date) {
  // let newDate = date.split(' ')
  // if (newDate.length === 2) {
  //     let str = newDate[0] + 'T' + newDate[1]
  //     return str
  // }
  // else {
  //     return date;
  // }
  moment(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function getInDateFormat(data) {
  return (
    new Date(data).getMonth() +
    1 +
    "/" +
    new Date(data).getDate() +
    "/" +
    new Date(data).getFullYear()
  );
}
export function getInDateAnotherFormat(data) {
  return (
    (new Date(data).getMonth() + 1 < 10
      ? "0" + (new Date(data).getMonth() + 1)
      : new Date(data).getMonth() + 1) +
    "-" +
    (new Date(data).getDate() < 10
      ? "0" + new Date(data).getDate()
      : new Date(data).getDate()) +
    "-" +
    new Date(data).getFullYear()
  );
}
export function getInDateAnotherFormat2(data) {
  return (
    // new Date(data)?.getFullYear() + "-" +
    // (new Date(data)?.getMonth() + 1 < 10
    //     ? '0' + (new Date(data)?.getMonth() + 1) : new Date(data)?.getMonth() + 1) +
    // "-" +
    // (new Date(data)?.getDate() < 10 ? '0' + new Date(data)?.getDate() : new Date(data)?.getDate())
    moment(data).format("YYYY-MM-DD")
  );
}

export const EditProfileJSX = () => {
  const { history }: any = useContext(ContextUser);
  return (
    <span
      className="material-icons edit-profile-on-hover"
      onClick={() => {
        history.push("/createProfile");
        history.go(0);
      }}
    >
      edit
    </span>
  );
};

export const isAuthorForCourseOnlyForCreateCourse = (
  authors,
  createdBy,
  userData,
  status
) => {
  // let userSlug2 = 'abcd'
  // console.log('......authors', authors, createdBy, userSlug)
  let userSlug = userData?.userSlug;
  if (status === "APPROVED" || sessionStorage.getItem("isReadOnly")) {
    return false;
  } else if (createdBy && createdBy.slug === userSlug) return true;
  else if (
    authors &&
    authors.find((authorData) => authorData?.slug === userSlug)
  ) {
    return true;
  } else if (userData?.roles.includes(userRoles?.SYSTEM_ADMIN)) {
    return true;
  }
  return false;
};

export const TKLstaffRoles = {
  CONTENT_ADMIN: "CONTENT ADMIN",
  CONTENT_AUTHOR: "CONTENT AUTHOR",
  SYSTEM_ADMIN: "SYSTEM ADMIN",
  TKL_ACCOUNT_MANAGER: "TKL ACCOUNT MANAGER",
  TKL_FACILITATOR: "TKL FACILITATOR",
};

export const indexingFunction = (
  fragmentsData,
  fragmentIndex,
  setfragmentIndexing
) => {
  let indexing = fragmentIndex;
  let totalQuestionFragments = 0;
  //let fragmentInitialLength = props.fragments?.length
  fragmentsData.forEach((data, index) => {
    if (index <= fragmentIndex - 1) {
      switch (data.type) {
        // case "fileUpload":
        //     --indexing;
        //     setfragmentIndexing(indexing);
        //     break;
        case "richtext":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        case "discussionForm":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        case "inputButton":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        case "ImageSlider":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        case "downloadable":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        case "tabs":
          --indexing;
          setfragmentIndexing(indexing);
          break;
        default:
          setfragmentIndexing(indexing);
          break;
      }
    }
  });
};

export const sortingByProperties = (isDirectField, sortOn, sortOn2) => {
  return function (instance1, instance2) {
    if (!isDirectField) {
      console.log("....instance1", instance1?.[sortOn]);
    }
  };
};

//debounce function
// export const debounceFunction = (callbackFunction, timerValue, searchValue, timer) => {
//     clearTimeout(timer)
//     timer = setTimeout(() => {
//         callbackFunction(searchValue)
//     }, timerValue)
// }

export const monthData = [
  {
    value: "Jan",
    slug: 1,
  },
  {
    value: "Feb",
    slug: 2,
  },
  {
    value: "March",
    slug: 3,
  },
  {
    value: "April",
    slug: 4,
  },
  {
    value: "May",
    slug: 5,
  },
  {
    value: "June",
    slug: 6,
  },
  {
    value: "July",
    slug: 7,
  },
  {
    value: "August",
    slug: 8,
  },
  {
    value: "September",
    slug: 9,
  },
  {
    value: "October",
    slug: 10,
  },
  {
    value: "November",
    slug: 11,
  },
  {
    value: "December",
    slug: 12,
  },
];
export const redirectToUrl = (data) => {
  if (data.substring(0, 5) === "https" || data.substring(0, 4) === "http") {
    window.open(`${data}`);
  } else {
    window.open(`https://${data}`);
  }
};

export const isFreeUser = (roles) => {
  if (roles?.length === 1 && roles?.includes(userRoles?.TKL_MEMBER_FREE_USER))
    return true;
  return false;
};

export const autogrowQuestion = (e) => {
  e.target.style.height = "inherit";
  e.target.style.height = `${e.target.scrollHeight}px`;
};

export const isCreatorForDiscussionForum = (
  discussionForumComments,
  userProfileData
) => {
  return discussionForumComments?.some(
    (item) => item.userSlug || item.user?.slug === userProfileData?.userSlug
  );
};

export const streamMediaURL = (slug) => {
  //  console.log('....slug', slug)
  let urlItem = slug ? `${URL}/stream-media/${slug}` : `undefined`;
  //    console.log('....urlItem', urlItem)
  return urlItem;
};

export const getFirstColon = (title) => {
  if (title) {
    let index = title.indexOf(":");
    if (index > -1) {
      return title.substring(index + 1);
    }
    return "";
  }
};

export const isFileSizeLimitExceeded = (fileSize) => {
  return fileSize / (1024 * 1024) > 100;
};

export const getDateWithoutSeconds = (s, timezone) => {
  if (timezone === "EST") {
    // s = new Date(s);
    // const newDate = new Date(s.getTime() - s.getTimezoneOffset() * 60 * 1000);
    // s = s.toLocaleString();
    // console.log("moment", moment, "moment(s)", moment(s));
    // return s.substr(0, s.length - 6) + s.substr(-3);
    return moment.tz(s, "America/New_York").format("M/D/YYYY");
  }
  return s.substr(0, s.length - 8);
};

export const getLocalDateFromUTC = (date, format = "MM/DD/YYYY, hh:mm A") => {
  var d;
  if (date) {
    try {
      d = moment
        .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(format);
      if (d == "Invalid date") {
        throw new Error("Invalid date");
      }
    } catch (e) {
      // console.log(e);
      d = false;
    }
  } else {
    d = false;
  }

  return d;
};

export const debounce = function (func, timeout = 1000) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
